<template>
    <v-container fluid>
        <v-card class="header-card">
            <v-card-title class="card-title">Leagues</v-card-title>
            <div class="header-buttons">
                <v-btn class="my-2 mr-2" dark small color="black" @click="createLeague">
                    <v-icon dark>mdi-plus</v-icon>Create
                </v-btn>
            </div>
        </v-card>
        <array-view
            :items="leagues"
            :headers="displayInfo"
            :total-pages="totalPages"
            :page="page"
            :size="size"
            @onDelete="onDelete"
            @handleClick="handleClick"
            @paginationChange="paginationChange"
        />
    </v-container>
</template>

<script>
    import leagueService from '@/services/league'
    import ArrayView from '@/components/ArrayView'

    export default {
        name: 'Tier',
        components: { ArrayView },
        data: () => {
            return {
                successfullImport: null,
                leagues: [],
                displayInfo: [
                    {
                        text: 'Id',
                        value: 'id'
                    },
                    {
                        text: 'Title',
                        value: 'title'
                    },
                    {
                        text: 'Start Date',
                        value: 'startDate'
                    },
                    {
                        text: 'End Date',
                        value: 'endDate'
                    },
                    {
                        text: 'Active',
                        value: 'active'
                    }
                ],
                showImportDialog: false,
                size: 5,
                totalPages: 0,
                page: 1
            }
        },
        created() {
            this.page = this.$store.getters.getPage
            this.fetchAllLeagues()
        },
        methods: {
            paginationChange(page, size) {
                this.size = size
                this.page = page
                this.$store.dispatch('setPage', page)
                this.fetchAllLeagues()
            },
            handleClick(action, item) {
                this.$router.push(`/leagues/${item.id}/${action}`)
            },
            onDelete(item, page) {
                leagueService.deleteTier(item.id).then(() => {
                    this.page = page
                    this.fetchAllLeagues()
                })
            },
            createLeague() {
                this.$router.push(`/leagues/create`)
            },
            fetchAllLeagues() {
                leagueService.getLeagues(this.page, this.size).then(resp => {
                    this.leagues = resp.data.content.length > 0 ? resp.data.content : null
                    this.totalPages = resp.data.totalPages
                })
            }
        }
    }
</script>

<style scoped>
.card-title {
    display: flex;
}

.v-list-item {
    min-height: 30px !important;
}

.v-card {
    min-width: 250px;
    position: relative;
}

.header-card {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1%;
}

.header-buttons {
    align-self: center;
}

.edit-button:hover {
    background-color: whitesmoke;
    color: white;
}

.screen-card {
    padding: 4%;
}

.overflow-text {
    max-width: 79%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.pagination {
    justify-content: center;
}
</style>
